
/**
 * Function to find and parse a JSON file
 *
 * @export function
 * @param {string} url link to JSON file relative to root
 * @returns {Promise} Recommend to .then() functionality afterwards
 */
export default function FetchJSON (url: string) {
	return new Promise((resolve, reject) => {
		// Get the file at the url using fetch (GET)
		fetch(url, {
			method: 'GET',

			credentials: 'same-origin',
			headers: {
				Accept: 'application/json'
			}
		})
		// This occurs when we have a response (ASYNC)
			.then((response) => {
				// If we got something (200 OK) then return what we got
				if (response.ok) {
					// This will definitely have a .json() method because both response and reject implement body
					// could return any type including Object, string, array, number, but will not return a JSON
					return response.json()
				} else {
					// Otherwise return a reject to the promise
					return reject(response)
				}
			})
			.then((data) => resolve(data))
			.catch((e) => {
				reject(e)
			})
	})
}