












// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { School } from '@/types/School';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';

@Component({
    components: {
        Button
    }
})
export default class SchoolThumb extends Vue {
    @Prop() school!: School;
    @Prop() schoolClass!: string;
}
