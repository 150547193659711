
















// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import Artist from '@/types/Artist';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';

@Component({
    components: {
        Button
    }
})
export default class ArtistThumb extends Vue {
    @Prop() artist!: Artist;
    @Prop() artistClass!: string;
}
