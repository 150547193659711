
















































// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class Header extends Vue {
    @Prop() article!: HeaderArticle;
    @Prop() forceSmall!: boolean;

    private logoSrc: string = 'img/logo-170.png';
    private menuActive: boolean = false;

    private mounted(): void {
        window.addEventListener('scroll', this.updateScroll);
    }

    private updateScroll(): void {
        if (window.scrollY < window.innerHeight - 200 && !this.forceSmall) {
            this.logoSrc = 'img/logo-170.png'
        } else {
            this.logoSrc = 'img/logo-28.png'
        }
    }
}
