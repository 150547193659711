





























// @ is an alias to /src
import Article, {  ContactArticle, TextImageArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from './Block.vue';

@Component({
    components: {
    }
})
export default class Contact extends Block {
    @Prop() article!: ContactArticle;
}

