





























// @ is an alias to /src
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Button from '@/components/Button.vue';
import PageHeader from '@/components/PageHeader.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import View from '@/types/View';

import {PopupMap} from '@/maps/PopupMap';
import Article from '@/types/Article';
import NewsFeedArticle from '@/types/NewsFeedArticle';

@Component({
  components: {
    PageHeader,
    NavBar,
    Footer,
    Button
  }
})
export default class Popup extends Vue {
  @Prop() article!: NewsFeedArticle;

  private get headerArticle(): Article {
      return { title: this.article.title, type: this.article.type, class: this.article.class };
  }

  private getComponentType(type: string) : VueConstructor | undefined {
    return PopupMap.get(type);
  }

  private back(){
    console.log("Go back");
    //this.$router.push({path: '/year2'});
    this.$router.go(-1);
  }
}
