










import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faPause);

@Component
export default class Button extends Vue {

    @Prop() colour!: string;
    @Prop() text!: string;
    @Prop() icon!: string;

    private pointerdownHandler(evt: Event):void {
        this.$emit('tapped')
    }

    private get buttonIcon():string[] {
        if (this.icon === undefined || this.icon === '') {
            return ['fas', 'caret-right'];
        }
        return this.icon.split(' ');
    }
}

