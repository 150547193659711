







// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from '@/components/Block.vue';
import SchoolThumb from '@/components/SchoolThumb.vue';
import Character from '@/components/Character.vue';
import CharacterGraphic from '@/types/CharacterGraphic';

@Component({
    components:{
        SchoolThumb,
        Character
    }
})
export default class SchoolHub extends Block {

    @Prop() character!: CharacterGraphic;

    public mounted():void {
        console.log("Character: ", this.character);
    }

}
