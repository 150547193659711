












// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from '@/components/Block.vue';
import ArtistThumb from '@/components/ArtistThumb.vue';
import Character from '@/components/Character.vue';
import CharacterGraphic from '@/types/CharacterGraphic';

@Component({
    components:{
        ArtistThumb,
        Character
    }
})
export default class ArtistHub extends Block {

    private get character(): CharacterGraphic | undefined {
        console.log("Character: ", this.article.character);
        return this.article.character;
    }

}
