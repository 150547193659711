







// @ is an alias to /src
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Header from '@/components/Header.vue';
import NavBar from '@/components/NavBar.vue';
import View from '@/types/View';

import {ComponentMap} from '@/maps/ComponentMap';

@Component({
  components: {
    Header,
    NavBar
  }
})
export default class Page extends Vue {
  @Prop() view!: View;

  private getComponentType(type: string) : VueConstructor | undefined {
    return ComponentMap.get(type);
  }
}
