// NPM
import Vue from 'vue';


// Libs
import FetchJSON from "./libs/FetchJSON";

// Vue imports
import App from './App.vue';
import Page from '@/views/Page.vue';
import Popup from '@/views/Popup.vue';

import Content from './types/Content';
import VueRouter, { RouteConfig } from 'vue-router';

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret, fas, faPause } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ViewMap } from './maps/ViewMap';
import NewsFeedView from './types/NewsFeedView';

library.add(faUserSecret, fas, fab, faPause);

/*

{
                    "title": "When the sun disappeared",
                    "type": "Video",
                    "media": {
                        "src": "media/wfc-whenthesundisappears.mp4"
                    }
                },

                */

require('@/sass/global.scss');

Vue.use(VueRouter);
Vue.component('font-awesome-icon', FontAwesomeIcon);

const contentUri: string = './content.json';
FetchJSON(contentUri).then((contentJson) => {
    const content: Content = contentJson as Content;
    const routes: Array<RouteConfig> = [];

    content.views.forEach(view => {
        const route: RouteConfig = {
            path: (view.uri.indexOf('/') < 0) ? '/' + view.uri : view.uri,
            name: view.title,
            component: ViewMap.get(view.type),
            props: {
                view
            }
        };

        routes.push(route);

        if (view.type === 'news') {
            const newsView: NewsFeedView = view as NewsFeedView;
            newsView.articles.forEach(article => {
                const route: RouteConfig = {
                    path: '/' + view.uri + '/' + article.uri,
                    name: article.title,
                    component: Popup,
                    props: {
                        article
                    }
                }
                routes.push(route);
            });

            
        }

        
    });

    const router = new VueRouter({
        routes, 
        mode: 'hash',
        scrollBehavior (to, from, savedPosition){
            return {x: 0, y: 0}
        }
    });

    const v = new Vue({
        created: () =>
        {
            console.log('Created: ', routes);
        },
        router, 
        render: h=> h(App, {
            props: {
                content,
            }
        })
    }).$mount('#app')
    
});


