












// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from '@/components/Block.vue';
import SchoolThumb from '@/components/SchoolThumb.vue';
import Character from '@/components/Character.vue';
import CharacterGraphic from '@/types/CharacterGraphic';

@Component({
    components:{
        SchoolThumb,
        Character
    }
})
export default class SchoolHub extends Block {

    private get thumbClass():string {
        return (this.article.class.indexOf('red') > -1) ? 'bg-blue' : 'bg-pink';
    }

    private get character(): CharacterGraphic | undefined {
        console.log("Character: ", this.article.character);
        return this.article.character;
    }

}
