


























import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import NavBar from '@/components/NavBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import TextImage from '@/components/TextImage.vue';
import Footer from '@/components/Footer.vue';
import NewsThumb from '@/components/NewsThumb.vue';
import NewsFeedView from '@/types/NewsFeedView';
import NewsFeedArticle from '@/types/NewsFeedArticle';

import {ComponentMap} from '@/maps/ComponentMap';

@Component({
  components: {
    NavBar,
    PageHeader,
    NewsThumb,
    Footer,
    TextImage
  }
})
export default class NewsFeed extends Vue {
  @Prop() view!: NewsFeedView;

  private get newsItems(): NewsFeedArticle[][] {
    const newsItems: NewsFeedArticle[][] = [];
    let newsItemSection: NewsFeedArticle[] = [];
    let counter = 12;
    this.view.articles.forEach((article: NewsFeedArticle) => {
      counter -= (2 + article.size * 2);

      if (counter < 0) {
        newsItems.push(newsItemSection.map((item) => item));
        newsItemSection = [];
        newsItemSection.push(article);
        counter = 12 - (2 + article.size * 2);
      } else {
        newsItemSection.push(article);
      }
    });
    newsItems.push(newsItemSection.map((item) => item));

    return newsItems;
  }
}

