






















// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import NewsFeedArticle, { GalleryNewsFeedArticle } from '@/types/NewsFeedArticle';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ImageGallery extends Vue {
    @Prop() article!: GalleryNewsFeedArticle;

    private imageIndex: number = 0;

    private nextImage(): void {
        this.imageIndex ++;
    }

    private previousImage(): void {
        this.imageIndex--;
    }
}
