







import { Vue, Component } from 'vue-property-decorator';
import Page from '@/views/Page.vue';

@Component({
  components: {Page}
})
export default class App extends Vue {
  public mounted(): void {
    console.log("App mounted");
  }
}

