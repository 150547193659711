





















// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from '@/components/Block.vue';

@Component({
    
})
export default class Header extends Block {

    private readmoreHandler(): void {
        this.$router.push('/about');
    }
}
