import { VueConstructor } from "vue/types/umd";
import Header from '@/components/Header.vue';
import PageHeader from '@/components/PageHeader.vue';
import TextImage from '@/components/TextImage.vue';
import TeamSmash from '@/components/TeamSmash.vue';
import Social from '@/components/Social.vue';
import SchoolHub from '@/components/SchoolHub.vue';
import ArtistHub from '@/components/ArtistHub.vue';
import SchoolThumb from '@/components/SchoolThumb.vue';
import ArtistThumb from '@/components/ArtistThumb.vue';
import Video from '@/components/Video.vue';
import FullwidthImage from '@/components/FullwidthImage.vue';
import Workshops from '@/components/Workshops.vue';
import Contact from '@/components/Contact.vue';
import Footer from '@/components/Footer.vue';

export const ComponentMap : Map<string, VueConstructor> = new Map<string, VueConstructor>();
ComponentMap.set('Header', Header);
ComponentMap.set('PageHeader', PageHeader);
ComponentMap.set('TextImage', TextImage);
ComponentMap.set('TeamSmash', TeamSmash);
ComponentMap.set('Social', Social);
ComponentMap.set('Video', Video);
ComponentMap.set('FullwidthImage', FullwidthImage);
ComponentMap.set('Workshops', Workshops);
ComponentMap.set('Contact', Contact);
ComponentMap.set('Footer', Footer);
ComponentMap.set('SchoolHub', SchoolHub);
ComponentMap.set('ArtistHub', ArtistHub);
ComponentMap.set('SchoolThumb', SchoolThumb);
ComponentMap.set('ArtistThumb', ArtistThumb);