







































































// @ is an alias to /src
import Article, { HeaderArticle, TextImageArticle, VideoArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MediaTools from '@/libs/MediaTools';
import Block from './Block.vue';
import { isMobile } from '@/libs/Devices';


@Component({

})
export default class Video extends Block {
    @Prop() article!: VideoArticle;

    private video!: HTMLVideoElement;
	private videoPaused: boolean = true;
	private videoPercent: number = 0;
	private updateIntervalId: number = -1;
	private mouseOverVideo: boolean = false;
	private isFullscreen: boolean = false;
	private hasPlayed: boolean = false;

    private get MediaTools(): MediaTools {
		return MediaTools;
	}

    private get isMobile(): boolean {
		return isMobile.any() !== null;
	}

	private get videoHeight(): string {
		return 'auto';
	}

	private get videoWidth(): string {
		return '100%';
	}

	private get VideoEnabled(): boolean {
		return !!document.createElement('video').canPlayType;
	}

	private get showControls(): boolean {
		return this.hasPlayed && (this.videoPaused || this.mouseOverVideo);
	}

	private playPauseVideo(refId:any): void {
		const videoRef: HTMLVideoElement = this.$refs[refId] as HTMLVideoElement;
		if (videoRef !== undefined) {
			if (videoRef.paused) {
				videoRef.play();
			} else {
				videoRef.pause();
			}
		}
		console.log("Playpause");
	}

	private fullscreenButtonHandler(): void {
		this.isFullscreen = !this.isFullscreen;
		if (this.isFullscreen) {
			this.fullscreenVideo();
		} else {
			this.closeFullscreenVideo();
		}
	}

	private fullscreenVideo(): void {
		let thingRequestingFullscreen: HTMLVideoElement | HTMLDivElement = this.video;
		if (this.$refs.videoContainer !== undefined) {
			thingRequestingFullscreen = this.$refs.videoContainer as HTMLDivElement;
		}
		if (thingRequestingFullscreen.requestFullscreen) {
			thingRequestingFullscreen.requestFullscreen();
			// @ts-ignore
		} else if (thingRequestingFullscreen.mozRequestFullScreen) { /* Firefox */
		// @ts-ignore
			thingRequestingFullscreen.mozRequestFullScreen();
			// @ts-ignore
		} else if (thingRequestingFullscreen.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
		// @ts-ignore
			thingRequestingFullscreen.webkitRequestFullscreen();
			// @ts-ignore
		} else if (thingRequestingFullscreen.msRequestFullscreen) { /* IE/Edge */
		// @ts-ignore
			thingRequestingFullscreen.msRequestFullscreen();
		}
	}

	private closeFullscreenVideo() {
		if (document.exitFullscreen) {
			document.exitFullscreen();
			// @ts-ignore
		} else if (document.mozCancelFullScreen) { /* Firefox */
		// @ts-ignore
			document.mozCancelFullScreen();
			// @ts-ignore
		} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
		// @ts-ignore
			document.webkitExitFullscreen();
			// @ts-ignore
		} else if (document.msExitFullscreen) { /* IE/Edge */
		// @ts-ignore
			document.msExitFullscreen();
		}
	}

	private mounted(): void {
		this.video = this.$refs.video as HTMLVideoElement;
		if (this.video !== undefined) {
			this.video.addEventListener('play', this.videoStarted);
			this.video.addEventListener('pause', this.videoWasPaused);
			this.updateIntervalId = window.requestAnimationFrame(this.update);
		}
		
	}

	private unmounted(): void {
		if (this.updateIntervalId !== undefined && this.updateIntervalId > -1) {
			window.cancelAnimationFrame(this.updateIntervalId);
		}
	}

	private videoStarted(): void {
		this.hasPlayed = true;
		this.videoPaused = false;
	}

	private videoWasPaused(): void {
		this.videoPaused = true;
	}

	private mouseEnteredVideo(e: MouseEvent): void {
		this.mouseOverVideo = true;
	}
	private mouseLeftVideo(e: MouseEvent): void {
		this.mouseOverVideo = false;
	}

	private update(): void {
		this.videoPercent = this.video.currentTime / this.video.duration * 100;
		
		this.updateIntervalId = window.requestAnimationFrame(this.update);
	}

	private seekerDownHandler(): void {
		window.addEventListener('pointermove', this.audiobarMoveHandler);
		window.addEventListener('pointerup', this.seekerUpHandler);
	}
	private audiobarMoveHandler(e: PointerEvent): void {
		// console.log('E: ', e);
		const relX = this.getPointerPositionRelativeToSeekBar(e.clientX);
		this.seekTo(relX / this.innerBarWidth);
	}
	private seekerUpHandler(): void {
		window.removeEventListener('pointermove', this.audiobarMoveHandler);
		window.removeEventListener('pointerup', this.seekerUpHandler);
	}

	private seekTo(timeAsPercent: number) {
		this.video.currentTime = timeAsPercent * this.video.duration;
		this.videoPercent = timeAsPercent * 100;
	}

	private getPointerPositionRelativeToSeekBar(pageX: number): number {
		const outerbar = (this.$refs.videobarOuter as HTMLDivElement);
		const player = (this.$refs.videoplayer as HTMLDivElement);
		let relX = pageX - outerbar.getBoundingClientRect().left - 20; // 20 for border
		relX = Math.max(relX, 0);
		relX = Math.min(relX, this.innerBarWidth);
		return relX;
	}
	private get innerBarWidth(): number {
		const outerbar = (this.$refs.videobarOuter as HTMLDivElement);
		const innerBarWidth = outerbar.getBoundingClientRect().width - 28; // 20 for border and half seeker width
		return innerBarWidth;
	}
}

