











// @ is an alias to /src
import { FullwidthImageArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Block from './Block.vue';

@Component({
    components: {
        
    }
})
export default class FullwidthImage extends Block {
}

