




import Article from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';

@Component({
    components: {
        Button
    }
})
export default class Block extends Vue {
    @Prop() article!: Article;

    private get reversed(): boolean {
        return this.article.direction === 'right';
    }

    private get showTitle(): boolean {
        if (!this.article.class) return true;
        return !this.article.class.includes('no-title');
    }

}

