





















// @ is an alias to /src
import { HeaderArticle } from '@/types/Article';
import { School } from '@/types/School';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
import NewsFeedArticle from '@/types/NewsFeedArticle';

@Component({
    components: {
        Button
    }
})
export default class NewsThumb extends Vue {
    @Prop() article!: NewsFeedArticle;

    private get buttonTitle(): string {
        if(this.article.button) {
            return this.article.button; 
        }else if(this.article.type === 'Video') {
            return 'Watch film';
        } else if(this.article.type === 'Audio'){
            return 'Hear the audio play';
        } else {
            return 'View images'
        }
    }

    private get thumbDescription(): string {
        if (this.article.description.length < 255) {
            return this.article.description;
        }
        return this.article.description.substring(0, 255) + '...';
    }
}
