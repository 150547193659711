


































// @ is an alias to /src
import Article, { HeaderArticle, TextImageArticle } from '@/types/Article';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TeamSmashArticle } from '@/types/Article';
import Block from './Block.vue';

@Component({
    components: {
    }
})
export default class TeamSmash extends Block {
    @Prop() article!: TeamSmashArticle;
    private readmoreHandler(): void {
        this.$router.push('/artists');
    }
}

